<template>
	<v-main>
		<div id="core-view">
			<router-view />
		</div>
	</v-main>
</template>

<script>
export default {
	name: `CoreView`,

	metaInfo () {
		return {
			title: `Ambassadors - We Don't Have Time`
		}
	}
}
</script>
